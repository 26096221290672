import React, { useState } from 'react';
import Youtube from 'react-youtube';
import Img from 'gatsby-image';
import { graphql, navigate } from 'gatsby';
import Slide from 'react-reveal/Slide';
import Fade from 'react-reveal/Fade';
import { FaTimes } from 'react-icons/fa';
import { BiCopy } from 'react-icons/bi';
import Layout from 'src/layouts/Layout';
import RedArrow from 'src/images/red-arrow.svg';
import Share from 'src/images/share.svg';
import SiteMetadata from 'src/components/SiteMetadata.jsx';
import useSiteMetaData from 'src/hooks/useSiteMetaData.js';
import './portfolio.scss';

export const query = graphql`
  query Project($slug: String!) {
    singleProject: contentfulProject(slug: { eq: $slug }) {
      title
      subtitle
      slug
      id
      description {
        description
      }
      contributorsReferences {
        jobPosition
        people
      }
      thumbnail {
        localFile {
          childImageSharp {
            fluid {
              originalImg
              originalName
            }
          }
        }
      }
      mainContent {
        title
        localFile {
          childImageSharp {
            fluid {
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
      youtubeMainContent
      assets {
        id
        localFile {
          id
          childImageSharp {
            fluid {
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
      mainBehindTheSceneContent {
        title
        localFile {
          childImageSharp {
            fluid {
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
      youtubeMainBehindTheSceneContent
      behindTheScenes {
        title
        localFile {
          childImageSharp {
            fluid {
              ...GatsbyImageSharpFluid
            }
          }
        }
        description
        children {
          id
        }
      }
    }
    allProjects: contentfulProjectList {
      projects {
        slug
        title
        subtitle
        thumbnail {
          localFile {
            childImageSharp {
              fluid {
                ...GatsbyImageSharpFluid
              }
            }
          }
        }
      }
    }
  }
`;

function Portfolio(props) {
  // Get domain url
  const { siteURL } = useSiteMetaData();

  // Get all projects to handle Prev Next Button
  const allProjects = props?.data?.allProjects?.projects;

  // Full Project Object
  const projectDetail = props?.data?.singleProject;
  const mainContent = projectDetail?.mainContent;
  const mainBehindTheSceneContent = projectDetail?.mainBehindTheSceneContent;
  const behindTheScenes = projectDetail?.behindTheScenes;
  const youtubeMainContent = projectDetail?.youtubeMainContent;
  const youtubeMainBehindTheSceneContent =
    projectDetail?.youtubeMainBehindTheSceneContent;
  const isBehindTheSceneVisible =
    mainBehindTheSceneContent ||
    behindTheScenes ||
    youtubeMainBehindTheSceneContent;

  // States
  const [showModal, setShowModal] = useState(false);
  const [showSnackBar, setShowSnackBar] = useState(false);

  // Constants
  // currentProjectIndex: number
  const currentProjectIndex = allProjects.findIndex(
    project => project?.slug === props?.pageContext?.slug
  );

  // isPrevButtonDisabled : boolean === First index in array
  const isPrevButtonDisabled = currentProjectIndex === 0;

  // isNextButtonDisabled : boolean === Last index in array
  const isNextButtonDisabled = allProjects.length - 1 === currentProjectIndex;

  // Get URL without "https://" | ex output: domain.com/evil
  const URLToCopied =
    siteURL.split('//')[1] + '/project/' + projectDetail?.slug;

  /**
   * Handle Previous button
   * @returns a navigated path in string | ex: '/evil'
   */
  const handlePrevButton = () => {
    let targetedPrevIndex = currentProjectIndex - 1;
    let targetedPrevSlug = allProjects[targetedPrevIndex]?.slug;
    return navigate(`/project/${targetedPrevSlug}`);
  };

  /**
   * Handle Next button
   * @returns a navigated path in string | ex: '/evil'
   */
  const handleNextButton = () => {
    let targetedNextIndex = currentProjectIndex + 1;
    let targetedNextSlug = allProjects[targetedNextIndex]?.slug;
    return navigate(`/project/${targetedNextSlug}`);
  };

  // To get youtube ID
  function YouTubeGetID(url) {
    url = url.split(/(vi\/|v=|\/v\/|youtu\.be\/|\/embed\/)/);
    //eslint-disable-next-line
    return url[2] !== undefined ? url[2].split(/[^0-9a-z_\-]/i)[0] : url[0];
  }

  // Handle copy to clipboard
  const handleCopyURL = () => {
    navigator.clipboard.writeText(URLToCopied);
    setShowSnackBar(true);
    setTimeout(() => {
      setShowSnackBar(false);
    }, 2500);
  };

  const handleShare = () => {
    if ('share' in navigator) {
      navigator
        .share({
          title: `Share ${projectDetail?.title} project`,
          url: `https://${URLToCopied}`,
        })
        .then(() => {
          // Callback after sharing
        })
        .catch(err => {
          console.error(err);
        });
    } else {
      setShowModal(true);
    }
  };

  // Youtube API: https://developers.google.com/youtube/player_parameters
  const opts = {
    playerVars: {
      autoplay: 1,
      mute: 1,
      color: 'white',
      controls: 0,
      rel: 0, // turn off related more videos
      // showinfo: 1, // ???
    },
  };

  // Turn off autoplay for BTS youtube
  const btsOpts = {
    playerVars: {
      autoplay: 0,
      mute: 1,
      color: 'white',
      controls: 0,
      rel: 0, // turn off related more videos
      // showinfo: 1, // ???
    },
  };

  return (
    <Layout>
      <SiteMetadata
        title={projectDetail?.title}
        description={projectDetail?.description?.description}
        image={
          projectDetail?.thumbnail?.localFile?.childImageSharp?.fluid
            ?.originalImg
        }
      />
      {/* Big Thumbnail */}
      <div className='hero-container portfolio-video-container relative flex-center-center portfolio-container'>
        {/* Youtube Asset */}
        {(!!youtubeMainContent ||
          mainContent?.localFile?.childImageSharp === null) && (
          <Youtube
            videoId={YouTubeGetID(youtubeMainContent ?? mainContent.title)}
            opts={opts}
            containerClassName='w-full'
            className='iframe-video'
          />
        )}
        {/* Image asset */}
        {/* <img alt="Logo" className="standard-hero" src={MAHHbackground} /> */}
        {mainContent?.localFile?.childImageSharp?.fluid && (
          <div style={{ width: '100%' }}>
            <Fade bottom>
              <Img
                fluid={mainContent?.localFile?.childImageSharp?.fluid}
                alt='project'
              />
            </Fade>
          </div>
        )}
      </div>
      {/* Heading, Description, Texts */}
      <div className='portfolio-upper-wrapper portfolio-container'>
        <div className='mb-8 mt-4 portfolio-detail-header-container text-white'>
          <div className='portfolio-detail-title relative'>
            <div className='left-red-line'></div>
            <Slide left>
              <h2 className='text-3xl pl-2'>{projectDetail?.title}</h2>
              <h3 className='text-2xl pl-2'>{projectDetail?.subtitle}</h3>
            </Slide>
          </div>
        </div>
        <div className='portfolio-detail-text-content flex flex-col md:flex-row text-white'>
          {/* Description */}
          <Slide left>
            <p className='portfolio-detail-paragraph'>
              {projectDetail?.description?.description}
            </p>
          </Slide>
          {/* Job title and contributor */}
          <div className='portfolio-detail-job-desc-container mt-8 md:mt-0 md:ml-20'>
            {projectDetail?.contributorsReferences?.map(
              (contributor, index) => {
                return (
                  <div
                    className='single-job-desc-container mb-8 md:mb-0'
                    key={index}
                  >
                    <Slide right>
                      <h5 className='font-bold'>{contributor?.jobPosition}</h5>
                      {contributor?.people?.map(person => {
                        return <h5 key={person}>{person}</h5>;
                      })}
                    </Slide>
                  </div>
                );
              }
            )}
          </div>
        </div>
      </div>
      {/* DESIGN */}
      <div className='portfolio-design-wrapper portfolio-container'>
        <Fade bottom>
          <div className='mahh-section-title-border-wrapper'>
            <h2 className='mahh-section-title-border text-center mb-4'>
              DESIGN
            </h2>
          </div>
        </Fade>
        {/* <h2 className='mahh-section-title text-white text-center portfolio-container'>DESIGN</h2> */}
        <div className='portfolio-images-wrapper row-helper portfolio-container'>
          {projectDetail?.assets?.map((asset, index) => {
            return (
              <div
                className='col-sm-12 col-md-6 p-4 mx-auto xl:max-w-screen-xl'
                key={index}
                style={{ width: '100%' }}
              >
                {/* <img src={PPortfolio} alt='portfolio' /> */}
                <Fade bottom>
                  <Img
                    fluid={asset?.localFile?.childImageSharp?.fluid}
                    alt='project'
                  />
                </Fade>
              </div>
            );
          })}
        </div>
      </div>
      {/* Behind The Scene */}
      {isBehindTheSceneVisible && (
        <div className='portfolio-design-wrapper portfolio-container'>
          {/* <h2 className='mahh-section-title text-white text-center portfolio-container mt-16 mb-4'>BEHIND THE SCENE</h2> */}
          <div className='mahh-section-title-border-wrapper mt-16'>
            <Fade bottom>
              <h2 className='mahh-section-title-border text-center mb-4'>
                BEHIND THE SCENE
              </h2>
            </Fade>
          </div>
          {/* Main BTS Content */}
          {/* Youtube Asset */}
          {(!!youtubeMainBehindTheSceneContent ||
            mainBehindTheSceneContent?.localFile?.childImageSharp === null) && (
            <Fade bottom>
              <Youtube
                videoId={YouTubeGetID(
                  youtubeMainBehindTheSceneContent ??
                    mainBehindTheSceneContent.title
                )}
                opts={btsOpts}
                containerClassName='w-full'
                className='iframe-video'
              />
            </Fade>
          )}
          {/* Image asset */}
          {mainBehindTheSceneContent?.localFile?.childImageSharp?.fluid && (
            <div style={{ width: '100%' }}>
              <Fade bottom>
                <Img
                  fluid={
                    mainBehindTheSceneContent?.localFile?.childImageSharp?.fluid
                  }
                  alt='project'
                />
              </Fade>
            </div>
          )}
          {/* BTS Contents */}
          <div className='portfolio-images-wrapper row-helper portfolio-container mt-4'>
            {behindTheScenes
              ?.filter(filteredBts => filteredBts?.localFile?.childImageSharp)
              .map((bts, index) => {
                return (
                  <div
                    className='col-sm-12 col-md-6 p-4 mx-auto xl:max-w-screen-xl'
                    key={index}
                    style={{ width: '100%' }}
                  >
                    <Fade bottom>
                      <Img
                        fluid={bts?.localFile?.childImageSharp?.fluid}
                        alt='project'
                      />
                    </Fade>
                  </div>
                );
              })}
          </div>
        </div>
      )}
      {/* Prev and Next Button */}
      <div className='portfolio-buttons-wrapper flex-center-center mt-16'>
        {isPrevButtonDisabled ? (
          <button
            style={{ opacity: '0.5' }}
            className='previous-button cursor-not-allowed flex items-center mr-8 sm:mr-20'
          >
            <img
              className='red-arrow w-2 sm:w-4 previous-arrow'
              src={RedArrow}
              alt='previous button'
            />
            <span className='text-white font-bold text-base sm:text-2xl ml-6'>
              PREVIOUS
            </span>
          </button>
        ) : (
          <button
            className='previous-button flex items-center mr-8 sm:mr-20'
            onClick={handlePrevButton}
          >
            <img
              className='red-arrow w-2 sm:w-4 previous-arrow'
              src={RedArrow}
              alt='previous button'
            />
            <span className='text-white font-bold text-base sm:text-2xl ml-6'>
              PREVIOUS
            </span>
          </button>
        )}
        {isNextButtonDisabled ? (
          <button
            style={{ opacity: '0.5' }}
            className='cursor-not-allowed next-button flex items-center ml-8 sm:ml-20'
          >
            <span className='text-white font-bold text-base sm:text-2xl mr-6'>
              NEXT
            </span>
            <img
              src={RedArrow}
              className='red-arrow w-2 sm:w-4'
              alt='previous button'
            />
          </button>
        ) : (
          <button
            className='next-button flex items-center ml-8 sm:ml-20'
            onClick={handleNextButton}
          >
            <span className='text-white font-bold text-base sm:text-2xl mr-6'>
              NEXT
            </span>
            <img
              src={RedArrow}
              className='red-arrow w-2 sm:w-4'
              alt='previous button'
            />
          </button>
        )}
      </div>

      {/* Share Floating Button */}
      <button className='share-floating-button' onClick={handleShare}>
        <img src={Share} alt='share' />
      </button>
      {/* Share Modal */}
      <div
        className={`share-modal-backdrop-wrapper fixed z-10 inset-0 overflow-y-auto text-white cursor-pointer ${
          showModal ? 'show visible' : 'invisible'
        }`}
        onClick={() => setShowModal(false)}
        role='presentation'
      >
        <div className='share-modal-centered flex justify-center items-center h-screen'>
          <div className='share-box-wrapper flex'>
            {/* Link Box to copy */}
            <button
              className='share-box-content p-2 sm:p-4 text-sm sm:text-base'
              onClick={handleCopyURL}
            >
              {URLToCopied}
            </button>
            {/* 2 Buttons */}
            <div className='share-box-buttons flex'>
              <button
                className='p-2 sm:p-4 background-red-ds'
                onClick={handleCopyURL}
              >
                <BiCopy className='h-8 w-auto fill-current' />
              </button>
              <button className='p-2 sm:p-4 background-red-ds'>
                <FaTimes className='h-8 w-auto fill-current' />
              </button>
            </div>
          </div>
        </div>
      </div>
      {/* Snackbar */}
      <div
        className={`snackbar background-red-ds ${showSnackBar ? 'show' : ' '}`}
      >
        Copied to clipboard
      </div>
    </Layout>
  );
}

export default Portfolio;
